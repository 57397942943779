.is_dark {
  --primary-color: #3e3444;
  --primary-color2: #fff;
  --primary-color3: #bb46b3;
  --primary-color4: rgba(255, 255, 255, 0.9);
  --primary-color5: #030303;
  --primary-color6: #fff;
  --primary-color7: #3e3444;
  --bg-section2: #1e141f;
  --primary-color8: #fff;
  --primary-color9: #8a8aa0;

  --default-banner-url: url(../images/default-banner.png);
  --profile-banner-url: url(../images/profile-banner.png);
}

/* Default banner height  */
.flat-title-page.inner {
  padding: 150px 0 !important;
}

/* Collection banner image */
#\/collection-banner {
  background: url(../images/collection-banner.png) center center no-repeat !important;
  background-size: cover !important;
}

/* Launchpad banner image */
#\/launchpad-banner {
  background: url(../images/launchpad-banner.png) center center no-repeat !important;
  background-size: cover !important;
}

/* FAQ banner image */
#\/faq-banner {
  background: url(../images/faq-banner.png) center center no-repeat !important;
  background-size: cover !important;
}

/* Edit Profile banner image */
#\/edit-profile-banner {
  background: url(../images/edit-profile-banner.png) center center no-repeat !important;
  background-size: cover !important;
}

/* Receipt banner image */
#\/receipt-banner {
  background: url(../images/receipt-banner.png) center center no-repeat !important;
  background-size: cover !important;
}

/* Balance banner image */
#\/balance-banner {
  background: url(../images/balance-banner.png) center center no-repeat !important;
  background-size: cover !important;
}
