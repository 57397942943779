.object-fit-cover {
  object-fit: cover;
}

.min-h-50 {
  min-height: 50vh;
}

.min-h-100 {
  min-height: 100vh;
}

/* For search bar + search page */

.is_dark .header-search-form-container input {
  background-color: var(--bg-section);
  border-color: var(--bg-section);
}

.is_dark .header-search-form-container input:focus {
  border-color: var(--primary-color3);
}

.search-tab-list {
  display: flex;
  width: 100%;
  background-color: var(--primary-color7);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-bottom: 5rem;
}

@media (max-width: 600px) {
  .search-tab-list {
    flex-wrap: wrap;
  }
}

.search-tab-list .search-tab {
  padding: 0.3em;
  width: 100%;
  cursor: pointer;
}

.search-tab-list .react-tabs__tab--selected {
  background-color: var(--primary-color4);
}

ul[role='tablist'] li:last-child {
  margin-right: 0 !important;
}

@media (max-width: 600px) {
  ul[role='tablist'] {
    flex-direction: column !important;
  }

  ul[role='tablist'] li {
    text-align: left !important;
    padding: 0.5em 1em !important;
  }

  .tab-authors ul[role='tablist'] li {
    text-align: center !important;
    padding: 10px 0px !important;
  }

  ul[role='tablist'] li::after {
    display: none !important;
  }
}
